import React, { FC } from "react"
import Text from "../Text"
import { FlightsParams } from "../../models/types"
import { DB_DATE_FORMAT, DISPLAY_DATE_FORMAT, I18N_NS } from "../../utils/constants"
import moment from "moment"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import TodayIcon from "@material-ui/icons/Today"
import PersonIcon from "@material-ui/icons/Person"
import ChildIcon from "@material-ui/icons/ChildCare"
import styles from "./SearchCard.styles"
import { useTranslation } from "react-i18next"

export interface Props {
  flights: FlightsParams
}

const FlightsCard: FC<Props> = ({ flights }) => {
  const { t } = useTranslation(I18N_NS)

  return (
    <>
      <div className={styles.row}>
        <LocationOnIcon className={styles.icon} />
        {flights.from?.map((from, index) => (
          <Text size={14}>
            {`${index > 0 && index <= from.length - 1 ? " / " : ""}${from}${
              flights.to?.[index] ? ` - ${flights.to?.[index]}` : ""
            }`}
          </Text>
        ))}
      </div>
      <div className={styles.row} />
      <div className={styles.row}>
        <TodayIcon className={styles.icon} />
        <Text size={14}>{`${moment(flights.dates?.[0], DISPLAY_DATE_FORMAT, true).format(DB_DATE_FORMAT)}${
          flights.dates?.[flights.dates.length - 1]
            ? ` - ${moment(flights.dates?.[flights.dates.length - 1], DISPLAY_DATE_FORMAT, true).format(
                DB_DATE_FORMAT
              )}`
            : ""
        }`}</Text>
      </div>
      <div className={styles.row} />
      <div className={styles.row}>
        <PersonIcon className={styles.icon} />
        <Text size={14}>{t("SearchCard.adults", { count: flights.adults + flights.seniors + flights.disabled })}</Text>
        {flights.children + flights.infants > 0 && (
          <>
            <ChildIcon className={styles.icon} />
            <Text size={14}>{t("SearchCard.child", { count: flights.children + flights.infants })}</Text>
          </>
        )}
      </div>
    </>
  )
}

export default FlightsCard
