import React from "react"

import moment from "moment"
import { useTranslation } from "react-i18next"

import { useTheme } from "@basset-la/themed-components"

import { AccommodationCatalog } from "../../model/accommodationDetail"
import { Rate } from "../../model/types"
import { I18N_NS } from "../../utils/constants"
import { calculateHighlightedAmenities } from "../../utils/helpers"
import AccommodationClusterDesktopPriceBreakdown from "../AccommodationClusterDesktopPriceBreakdown"
import RoomBrief from "../RoomBrief/RoomBrief"
import SimpleAccommodationInfo from "../SimpleAccommodationInfo"
import styles from "./QuotedPDF.styles"
import QuotedPDFHeader from "./QuotedPDFHeader"
import InfoIcon from "@material-ui/icons/Info"

export interface Props {
  number: number
  accommodation: AccommodationCatalog
  rate: Rate
  roomsQty: number[]
  rooms?: string[]
  regionName: string
  showPriceBreakDown?: boolean
  adultsCount: number
  childrenAges?: number[]
}

const QuotedPDF: React.FC<Props> = ({
  number,
  accommodation,
  rate,
  roomsQty,
  regionName,
  rooms,
  showPriceBreakDown,
  adultsCount,
  childrenAges = []
}) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const highlightedAmenities = calculateHighlightedAmenities(accommodation.amenities)

  const qty = roomsQty.reduce((p: number, a: number) => a + p, 0)
  const nights = moment(rate.checkout).diff(moment(rate.checkin), "days")

  const guestCount = adultsCount + childrenAges.length

  return (
    <div className={styles.root(theme)}>
      <div>
        <QuotedPDFHeader
          total={rate.price ? rate.price.total : 0}
          number={number}
          currency={rate.price ? rate.price.currency : ""}
          checkin={rate.checkin}
          checkout={rate.checkout}
          roomsQty={qty}
          regionName={regionName}
          adultsCount={adultsCount}
          childrenAges={childrenAges}
        />
        <div className={styles.mainContent}>
          <div className={styles.container(theme)}>
            <img className={styles.photos} src={accommodation.images[0].url} alt={accommodation.name} />
            <div className={styles.infoContainer}>
              <SimpleAccommodationInfo
                name={accommodation.name}
                address={accommodation.address}
                stars={accommodation.stars}
                mealPlan={rate.mealPlan}
                freeCancel={rate.refundable}
                {...highlightedAmenities}
              />
            </div>
            {showPriceBreakDown && rate.fare && !!guestCount && (
              <div className={styles.priceBreakdown(theme)}>
                <AccommodationClusterDesktopPriceBreakdown
                  accommodationFare={rate.fare}
                  guestsCount={guestCount}
                  nightsCount={nights}
                  provider={rate.provider}
                  roomsCount={qty}
                  hideCostEffectiveness
                />
              </div>
            )}
          </div>
          {rooms &&
            rooms.length > 0 &&
            rooms.map((r: string, i: number) => (
              <div key={i} className={styles.roomBriefContainer}>
                <RoomBrief
                  roomCount={roomsQty[i]}
                  roomName={r}
                  mealPlan={rate.mealPlan}
                  cancelPenalties={rate.cancelPenalties}
                  refundable={rate.refundable}
                  expirationDate={rate.expirationDate}
                />
              </div>
            ))}
        </div>
      </div>
      <p className={styles.disclamer(theme)}>{t("QuotedPDF.disclamer")}</p>

      {rate.remarks && (
        <div className={styles.remarksSection}>
          <span className={styles.remarkHeader}>
            <InfoIcon className={styles.remarkIcon} />
            {t("QuotedPDF.remarksHeader")}
          </span>
          <span data-testid="remarks" className={styles.remarkDescription(theme)}>
            {rate.remarks}
          </span>
        </div>
      )}
    </div>
  )
}

export default QuotedPDF
