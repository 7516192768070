import React, { FC, useEffect, useState } from "react"
import Text from "../Text"
import { TravelInsuranceParams } from "../../models/types"
import { DB_DATE_FORMAT, I18N_NS, INPUT_DATETIME_FORMAT } from "../../utils/constants"
import moment from "moment"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import TodayIcon from "@material-ui/icons/Today"
import PersonIcon from "@material-ui/icons/Person"
import ChildIcon from "@material-ui/icons/ChildCare"
import styles from "./SearchCard.styles"
import { useTranslation } from "react-i18next"
import { Skeleton } from "@material-ui/lab"

export interface Props {
  insurances: TravelInsuranceParams
  getLocationName: (code: string, type: string) => Promise<string>
}

const TravelInsurancesCard: FC<Props> = ({ insurances, getLocationName }) => {
  const { t } = useTranslation(I18N_NS)
  const [origenRegion, setOrigenRegion] = useState<string>("")
  const [destionationRegion, setDestionationRegion] = useState<string>("")
  const [totalAdults, setTotalAdults] = useState<number>(0)
  const [totalChildren, setTotalChildren] = useState<number>(0)

  useEffect(() => {
    if (insurances.origin_region_id) {
      getLocationName(insurances.origin_region_id, insurances.origin_region_type).then(location =>
        setOrigenRegion(location)
      )
    }
    if (insurances.destination_region_id) {
      getLocationName(insurances.destination_region_id, insurances.destination_region_type).then(location =>
        setDestionationRegion(location)
      )
    }
  }, [insurances, getLocationName])

  useEffect(() => {
    const adults = insurances.ages.filter(age => age >= 18).length
    setTotalAdults(adults)
    setTotalChildren(insurances.ages.length - adults)
  }, [insurances])

  if (!origenRegion || !destionationRegion) {
    return (
      <>
        <div className={styles.row}>
          <Skeleton variant="rect" animation="pulse" width="100%" height="20px" />
        </div>
        <div className={styles.row}>
          <Skeleton variant="rect" animation="pulse" width="100%" height="20px" />
        </div>
        <div className={styles.row}>
          <Skeleton variant="rect" animation="pulse" width="100%" height="20px" />
        </div>
      </>
    )
  }

  return (
    <>
      <div className={styles.row}>
        <LocationOnIcon className={styles.icon} />
        <Text size={14}>{`${origenRegion} - ${destionationRegion}`}</Text>
      </div>
      <div className={styles.row}>
        <TodayIcon className={styles.icon} />
        <Text size={14}>{`${moment(insurances.from, INPUT_DATETIME_FORMAT, true).format(DB_DATE_FORMAT)}${
          insurances.to ? ` - ${moment(insurances.to, INPUT_DATETIME_FORMAT, true).format(DB_DATE_FORMAT)}` : ""
        }`}</Text>
      </div>
      <div className={styles.row}>
        <PersonIcon className={styles.icon} />
        <Text size={14}>{t("SearchCard.passenger", { count: totalAdults })}</Text>
        {totalChildren > 0 && (
          <>
            <ChildIcon className={styles.icon} />
            <Text size={14}>{t("SearchCard.child", { count: totalChildren })}</Text>
          </>
        )}
      </div>
    </>
  )
}

export default TravelInsurancesCard
