import React, { FC, useEffect, useState } from "react"
import Text from "../Text"
import { AccommodationsParams } from "../../models/types"
import { DB_DATE_FORMAT, DISPLAY_DATE_FORMAT, I18N_NS } from "../../utils/constants"
import moment from "moment"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import TodayIcon from "@material-ui/icons/Today"
import PersonIcon from "@material-ui/icons/Person"
import ChildIcon from "@material-ui/icons/ChildCare"
import styles from "./SearchCard.styles"
import { Skeleton } from "@material-ui/lab"
import { useTranslation } from "react-i18next"
import { getDistribution } from "./utils"

export interface Props {
  accommodations: AccommodationsParams
  getLocationName: (code: string, type: string) => Promise<string>
}

const AccommodationsCard: FC<Props> = ({ accommodations, getLocationName }) => {
  const { t } = useTranslation(I18N_NS)
  const [regionName, setRegionName] = useState<string>("")

  const distributions = getDistribution(accommodations.distributions)

  useEffect(() => {
    if (accommodations.region_id) {
      getLocationName(accommodations.region_id, accommodations.region_type).then(location => setRegionName(location))
    }
  }, [accommodations, getLocationName])

  if (!regionName) {
    return (
      <>
        <div className={styles.row}>
          <Skeleton variant="rect" animation="pulse" width="100%" height="20px" />
        </div>
        <div className={styles.row}>
          <Skeleton variant="rect" animation="pulse" width="100%" height="20px" />
        </div>
        <div className={styles.row}>
          <Skeleton variant="rect" animation="pulse" width="100%" height="20px" />
        </div>
      </>
    )
  }

  return (
    <>
      <div className={styles.row}>
        <LocationOnIcon className={styles.icon} />
        <Text size={14}>{regionName}</Text>
      </div>
      <div className={styles.row} />
      <div className={styles.row}>
        <TodayIcon className={styles.icon} />
        <Text size={14}>{`${moment(accommodations.checkin, DISPLAY_DATE_FORMAT, true).format(DB_DATE_FORMAT)}${
          accommodations.checkout
            ? ` - ${moment(accommodations.checkout, DISPLAY_DATE_FORMAT, true).format(DB_DATE_FORMAT)}`
            : ""
        }`}</Text>
      </div>
      <div className={styles.row} />
      <div className={styles.row}>
        <PersonIcon className={styles.icon} />
        <Text size={14}>{t("SearchCard.adults", { count: distributions.adults })}</Text>
        {distributions.children > 0 && (
          <>
            <ChildIcon className={styles.icon} />
            <Text size={14}>{t("SearchCard.child", { count: distributions.children })}</Text>
          </>
        )}
      </div>
    </>
  )
}

export default AccommodationsCard
