import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

const styles = {
  hotel: css({
    width: "calc(100vw - 32px)",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    top: "16px",
    left: "16px",
    zIndex: 2000,
    maxHeight: "240px",
    "@media(min-width: 1024px)": {
      width: "328px",
      maxHeight: "unset"
    }
  }),

  icon: css({
    position: "absolute",
    zIndex: 100,
    alignSelf: "end",
    color: "#ffffff",
    margin: "8px",
    cursor: "pointer"
  }),

  container: css({
    width: "100%",
    height: "100vh",
    position: "absolute",
    top: "0",
    left: "0",
    padding: "0 0 56px",
    backgroundColor: "#f0f0f0",
    "@media(min-width: 1024px)": {
      padding: "8px",
      top: "auto",
      left: "auto",
      position: "static"
    }
  }),

  mapPagination: css({
    display: "flex",
    flexDirection: "row",
    marginTop: "16px",
    padding: "16px 8px",
    backgroundColor: "rgb(207, 223, 252)",
    boxSizing: "border-box",
    borderRadius: "4px",
    border: "1px solid #4A81E2",
    alignItems: "center"
  }),

  infoIcon: css({
    "&.MuiSvgIcon-root": {
      color: "#4A81E2",
      marginRight: "8px"
    }
  }),

  link: (t: Theme) =>
    css({
      marginLeft: "8px",
      textDecorationLine: "underline",
      color: t.colors.brand_primary,
      cursor: "pointer"
    }),

  innerContainer: css({
    height: "100%",
    width: "100%",
    position: "relative"
  }),

  mapContainer: css({
    height: "100%",
    width: "100%"
  })
}

export default styles
