import React from "react"
import { AdvertisingData, AdvertisingPosition } from "./types"
import AdvBanner from "./AdvBanner"
import AdvFinancial from "./AdvFinancial"
import AdvSlider from "./AdvSlider"

export interface Props {
  data: AdvertisingData
  position: AdvertisingPosition
  price?: number
  currency?: string
}

const Advertising: React.FC<Props> = ({ data, position, price, currency }) => {
  return (
    <>
      {data.financial && position === "CLUSTER" && (
        <AdvFinancial financial={data.financial} price={price} currency={currency} />
      )}
      {data.banner && position === "TOP_RESULT" && <AdvBanner banner={data.banner} />}
      {data.slider && position === "TOP_RESULT" && <AdvSlider slider={data.slider} />}
    </>
  )
}

export default Advertising
