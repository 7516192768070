import React from "react"
import styles from "./SearchboxMobileBar.styles"
import { useTheme } from "@basset-la/themed-components"
import Divider from "@material-ui/core/Divider"
import PersonIcon from "@material-ui/icons/Person"
import HotelIcon from "@material-ui/icons/Hotel"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import { SearchboxParams } from "./types"

export interface Props {
  params: SearchboxParams
  onClick?: () => void
}

const SearchboxMobileBar: React.FC<Props> = ({ params, onClick }) => {
  const theme = useTheme()

  const handleClick = () => {
    if (onClick) onClick()
  }

  const occupation = params.rooms.reduce((v, r) => v + r.adults + r.children.length, 0)

  return (
    <div className={styles.root(theme)} onClick={handleClick}>
      <div className={styles.column}>
        <div className={styles.content}>
          <LocationOnIcon className={styles.icon(theme)} />
          {params.destination && params.destination.name}
        </div>
      </div>
      <div className={styles.divider}>
        <Divider orientation="vertical" />
      </div>
      <div className={styles.column}>
        {params.checkin
          ?.format("DD MMM")
          .substr(0, 6)
          .toUpperCase() || ""}
        {params.checkout && " - "}
        {params.checkout
          ?.format("DD MMM")
          .substr(0, 6)
          .toUpperCase() || ""}
      </div>
      <div className={styles.divider}>
        <Divider orientation="vertical" />
      </div>
      <div className={styles.column}>
        <div className={styles.content}>
          <HotelIcon className={styles.icon(theme)} />
          {params.rooms.length}
        </div>
        <div className={styles.content}>
          <PersonIcon className={styles.icon(theme)} />
          {occupation}
        </div>
        <KeyboardArrowDownIcon className={styles.icon(theme)} />
      </div>
    </div>
  )
}

export default SearchboxMobileBar
