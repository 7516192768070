export interface AdvertisingData {
  financial?: AdvertisingFinancialData
  banner?: AdvertisingBannerData
  slider?: AdvertisingSliderData
}

export type AdvertisingPosition = "CLUSTER" | "TOP_RESULT"

export const SLIDER_ANIMATION_INTERVAL = 6

export interface AdvertisingFinancialData {
  position: AdvertisingPosition
  card_code: string
  bank_code: string
  installments: number
  cft: number
  duration: number
  terms_and_conditions: string
}

export interface AdvertisingBannerData {
  position: AdvertisingPosition
  image: AdvertisingImageData
  duration: number
}

export interface AdvertisingSliderData {
  position: AdvertisingPosition
  images: Array<AdvertisingImageData>
  duration: number
}

export interface AdvertisingImageData {
  url: string
  alt: string
  link: string
}
