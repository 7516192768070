import React from "react"
import styles from "./Link.styles"
import { useTheme } from "@basset-la/themed-components"
import Text, { TextProps } from "../Text"

export interface Props {
  value: React.ReactNode
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  className?: string
  component?: "div" | "p" | "span"
  disabled?: boolean
  TextProps?: Partial<Pick<TextProps, "className" | "component" | "size" | "variant">>
}

const Link: React.FC<Props> = ({ value, onClick, className = "", component = "p", TextProps, disabled }) => {
  const theme = useTheme()
  const Component: React.ElementType = component

  const textProps = TextProps
    ? { ...TextProps, className: `${styles.text(theme, disabled)} ${TextProps.className}` }
    : { className: styles.text(theme, disabled) }

  return (
    <Component onClick={disabled ? undefined : onClick} className={`${styles.root} ${className}`}>
      <Text {...textProps}>{value}</Text>
    </Component>
  )
}

export default Link
