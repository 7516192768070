import React from "react"
import styles from "./ItineraryRecommendations.styles"
import {
  ItineraryRecommendations as ItineraryRecommendationsModel,
  RecommendedItinerary,
  OrderByOptions
} from "../../model"
import Recommendation from "./Recommendation"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Text from "@basset-la/components-commons/dist/components/Text"
import Select from "@basset-la/components-commons/dist/components/Select"

export interface Props {
  variant?: "WEB" | "DESKTOP"
  recommendations: ItineraryRecommendationsModel | null
  orderBy: OrderByOptions
  onSelectItinerary: (itinerary: RecommendedItinerary) => void
  onChangeOrder: (orderBy: OrderByOptions) => void
}

const ItineraryRecommendations: React.FC<Props> = ({
  variant = "DESKTOP",
  recommendations,
  orderBy,
  onSelectItinerary,
  onChangeOrder
}) => {
  const { t } = useTranslation(I18N_NS)

  const values = [
    { code: "price", name: t("Recommendation.cheapest") },
    { code: "recommendation", name: t("Recommendation.recommended") },
    { code: "duration", name: t("Recommendation.shortest_flight") }
  ]

  const handleSelectOrder = (v: string[]) => {
    if ((v[0] as OrderByOptions) !== orderBy) onChangeOrder(v[0] as OrderByOptions)
  }

  return (
    <div className={styles.root}>
      <div className={styles.item}>
        {recommendations && (
          <>
            <Recommendation
              variant="recommended"
              channel={variant}
              recommendations={recommendations}
              onSelectItinerary={onSelectItinerary}
            />
            <Recommendation
              variant="cheapest"
              channel={variant}
              recommendations={recommendations}
              onSelectItinerary={onSelectItinerary}
            />
            <Recommendation
              variant="shortest_flight"
              channel={variant}
              recommendations={recommendations}
              onSelectItinerary={onSelectItinerary}
            />
          </>
        )}
      </div>

      <div className={styles.item}>
        <Text size={14} variant="regular">
          {t("ItineraryRecommendations.orderBy")}
        </Text>
        <Select
          values={values}
          selected={[orderBy]}
          id="itinerary-recommentations-order-by-select"
          menuItemId="itinerary-recommentations-order-by-select-menu-item"
          onSelect={handleSelectOrder}
        />
      </div>
    </div>
  )
}

export default ItineraryRecommendations
