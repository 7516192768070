import React from "react"
import { PriceBreakdown } from "../../model"
import PriceboxRow from "@basset-la/components-products/dist/components/PriceboxRow"
import Pricebox from "@basset-la/components-products/dist/components/Pricebox"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import { useTheme } from "@basset-la/themed-components"
import Skeleton from "@material-ui/lab/Skeleton/Skeleton"

type LoadingTrueProps = {
  loading: true
  priceBreakdown?: PriceBreakdown
}

type LoadingFalseProps = {
  loading?: false
  priceBreakdown: PriceBreakdown
}

type Props = { taxesDisclaimer?: string } & (LoadingTrueProps | LoadingFalseProps)

const PriceboxBreakdownContainer: React.FC<Props> = ({ loading = false, priceBreakdown, taxesDisclaimer }) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)

  if (loading) {
    return (
      <div>
        <Pricebox
          backgroundColor={theme.colors.brand_primary}
          textColor={theme.colors.text_white}
          title={t("AccommodationsPricebox.title")}
          totalLabel={t("AccommodationsPricebox.total")}
          totalValue=""
          startCollapsed={true}
        >
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
        </Pricebox>
      </div>
    )
  }

  const { total, currency, baseRate, taxes, charges, rooms, guests } = priceBreakdown!

  return (
    <div>
      <Pricebox
        backgroundColor={theme.colors.brand_primary}
        textColor={theme.colors.text_white}
        title={t("AccommodationsPricebox.title")}
        totalLabel={t("AccommodationsPricebox.total")}
        totalValue={formatPrice(total, locale, currency)}
        startCollapsed={true}
        taxesDisclaimer={taxesDisclaimer}
      >
        <PriceboxRow
          label={`${t("AccommodationsPricebox.room", { count: rooms })} -  ${t("AccommodationsPricebox.guest", {
            count: guests
          })}`}
          value={formatPrice(baseRate, locale, currency)}
        />
        <PriceboxRow
          label={t("AccommodationsPricebox.taxes")}
          value={formatPrice(taxes.total, locale, currency)}
          variant={
            taxes.agencyVAT === 0 &&
            taxes.resellerVAT === 0 &&
            taxes.roomVAT === 0 &&
            !taxes.remainder &&
            !taxes.pais &&
            !taxes.rg4815 &&
            !taxes.rg5272
              ? undefined
              : "collapsible"
          }
        >
          {taxes.agencyVAT !== undefined && taxes.agencyVAT > 0 && (
            <PriceboxRow
              label={t("AccommodationsPricebox.agencyVAT")}
              value={formatPrice(taxes.agencyVAT, locale, currency)}
            />
          )}
          {taxes.resellerVAT > 0 && (
            <PriceboxRow
              label={t("AccommodationsPricebox.resellerVAT")}
              value={formatPrice(taxes.resellerVAT, locale, currency)}
            />
          )}
          {taxes.roomVAT > 0 && (
            <PriceboxRow
              label={t("AccommodationsPricebox.roomVAT")}
              value={formatPrice(taxes.roomVAT, locale, currency)}
            />
          )}
          {taxes.pais && (
            <PriceboxRow
              label={t("AccommodationsPricebox.paisTax")}
              value={formatPrice(taxes.pais, locale, currency)}
            />
          )}
          {taxes.rg4815 && (
            <PriceboxRow
              label={t("AccommodationsPricebox.rg4815Tax")}
              value={formatPrice(taxes.rg4815, locale, currency)}
            />
          )}
          {taxes.rg5272 && (
            <PriceboxRow
              label={t("AccommodationsPricebox.rg5272Tax")}
              value={formatPrice(taxes.rg5272, locale, currency)}
            />
          )}

          {taxes.taxes?.map(t => (
            <PriceboxRow label={t.type} value={formatPrice(t.amount, locale, currency)} />
          ))}

          {taxes.remainder && (
            <PriceboxRow
              label={t("AccommodationsPricebox.taxes")}
              value={formatPrice(taxes.remainder, locale, currency)}
            />
          )}
        </PriceboxRow>
        <PriceboxRow
          label={t("AccommodationsPricebox.charges")}
          value={formatPrice(charges.total, locale, currency)}
          variant="collapsible"
        >
          {charges.resellerCharge && charges.resellerCharge.total > 0 && (
            <PriceboxRow
              label={t("AccommodationsPricebox.resellerCharges")}
              value={formatPrice(charges.resellerCharge.total, locale, currency)}
              variant="collapsible"
            >
              {charges.resellerCharge.operationCosts > 0 && (
                <PriceboxRow
                  label={t("AccommodationsPricebox.operatingCost")}
                  value={formatPrice(charges.resellerCharge.operationCosts, locale, currency)}
                />
              )}
              {charges.resellerCharge.adminCosts > 0 && (
                <PriceboxRow
                  label={t("AccommodationsPricebox.adminitrativeCosts")}
                  value={formatPrice(charges.resellerCharge.adminCosts, locale, currency)}
                />
              )}
              {charges.resellerCharge.additionalFee && (
                <PriceboxRow
                  label={t("AccommodationsPricebox.fees")}
                  value={formatPrice(charges.resellerCharge.additionalFee, locale, currency)}
                />
              )}
            </PriceboxRow>
          )}
          {charges.agencyCharge && charges.agencyCharge.total > 0 && (
            <PriceboxRow
              label={t("AccommodationsPricebox.agencyCharges")}
              value={formatPrice(charges.agencyCharge.total, locale, currency)}
              variant="collapsible"
            >
              {charges.agencyCharge.markup > 0 && (
                <PriceboxRow
                  label={t("AccommodationsPricebox.markup")}
                  value={formatPrice(charges.agencyCharge.markup, locale, currency)}
                />
              )}
              {charges.agencyCharge.operationCosts > 0 && (
                <PriceboxRow
                  label={t("AccommodationsPricebox.operatingCost")}
                  value={formatPrice(charges.agencyCharge.operationCosts, locale, currency)}
                />
              )}
              {charges.agencyCharge.adminCosts > 0 && (
                <PriceboxRow
                  label={t("AccommodationsPricebox.adminitrativeCosts")}
                  value={formatPrice(charges.agencyCharge.adminCosts, locale, currency)}
                />
              )}
              {charges.agencyCharge.adjustment !== undefined && charges.agencyCharge.adjustment > 0 && (
                <PriceboxRow
                  label={t("AccommodationsPricebox.adjustment")}
                  value={formatPrice(charges.agencyCharge.adjustment, locale, currency)}
                />
              )}
              {charges.agencyCharge.additionalFee && (
                <PriceboxRow
                  label={t("AccommodationsPricebox.fees")}
                  value={formatPrice(charges.agencyCharge.additionalFee, locale, currency)}
                />
              )}
            </PriceboxRow>
          )}
        </PriceboxRow>
      </Pricebox>
    </div>
  )
}

export default PriceboxBreakdownContainer
