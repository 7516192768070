import React from "react"
import Card from "../Card"
import Text from "../Text"
import { useTheme } from "@basset-la/themed-components"
import styles from "./LastSearches.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import SearchCard from "../SearchCard"
import { AccommodationsParams, CarsParams, FlightsParams, Search, TravelInsuranceParams } from "../../models/types"

export interface LastSearchesProps {
  searches: Search[]
  onClick: (product: TravelInsuranceParams | CarsParams | FlightsParams | AccommodationsParams) => void
  getLocationName?: (code: string, type: string) => Promise<string>
}

const LastSearches: React.FC<LastSearchesProps> = ({ searches, getLocationName, onClick }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  return (
    <Card variant="outlined">
      <div className={styles.root(theme)}>
        <div className={`${styles.content}`}>
          <div className={styles.label}>
            <Text size={14}>{t("LastSearches.title")}</Text>
          </div>
          <div className={styles.children}>
            {searches.map((search, index) => (
              <SearchCard
                key={index}
                product={search.data}
                productType={search.product_type}
                getLocationName={getLocationName}
                onClick={onClick}
              />
            ))}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default LastSearches
