import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      width: "100%",
      padding: "8px 0",
      margin: "0",
      cursor: "pointer",
      fontFamily: "Roboto",
      color: t.colors.text_primary,
      backgroundColor: t.colors.background_primary,
      boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)"
    }),
  column: css({
    flex: "1 1 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    textAlign: "center",
    fontWeight: 500
  }),
  divider: css({
    width: "5px"
  }),
  icon: (t: Theme) =>
    css({
      color: t.colors.brand_primary
    }),
  content: css({
    display: "flex",
    alignItems: "center"
  })
}
