export const I18N_NS = "components-commons"

export const colors = {
  GREY: "#4a4a4a",
  LIGHTER_GREY: "#989898",
  WHITE: "#fff",
  BLUE: "#126aff",
  VERY_LIGHT_GREY: "rgba(0, 0, 0, 0.12)"
}

export const DISPLAY_DATE_FORMAT = "YYYY-MM-DD"

export const DB_DATE_FORMAT = "DD/MM/YYYY"

export const DISPLAY_DATETIME_FORMAT = "YYYY-MM-DD HH:mm"
export const INPUT_DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZ"
