import { css } from "@emotion/css"
import { colors } from "../../utils/constants/constants"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      height: "434px",
      fontFamily: "Roboto",
      color: theme.colors.text_primary,
      backgroundColor: theme.colors.background_primary,
      width: "100%",
      overflow: "hidden",
      position: "relative",
      boxSizing: "border-box",
      borderRadius: "4px",
      transition: "box-shadow .3s ease",
      boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
      ":hover": {
        boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 0 4px 0 rgba(0, 0, 0, 0.12)"
      }
    }),
  favoriteButton: (theme: Theme) =>
    css({
      "&.MuiIconButton-root": {
        position: "absolute",
        left: "28px",
        top: "28px",
        zIndex: 5,
        padding: "8px",
        transition: "all 250ms ease",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgba(255,255,255,0.75)",
        ":active": {
          backgroundColor: theme.colors.background_primary
        },
        ":hover": {
          backgroundColor: theme.colors.background_primary,
          " .MuiSvgIcon-root": {
            fontSize: "26px"
          }
        },
        " .MuiSvgIcon-root": {
          color: colors.RED3,
          transition: "all 250ms ease",
          fontSize: "24px"
        }
      }
    }),
  infoContainer: css({
    display: "flex",
    flexShrink: 1,
    flexGrow: 1,
    minWidth: 0
  }),
  container: css({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    flexShrink: 1,
    flexGrow: 1,
    minWidth: 0
  }),
  moreProviders: (theme: Theme) =>
    css({
      borderTop: `1px solid ${theme.colors.background_darker}`,
      minWidth: 0,
      height: "100px"
    }),
  priceBreakdown: (theme: Theme) =>
    css({
      display: "flex",
      boxSizing: "border-box",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      width: "257px",
      padding: "16px",
      borderLeft: `1px solid ${theme.colors.background_darker}`
    }),
  onViewButton: (theme: Theme) =>
    css({
      "&.MuiButton-root": {
        backgroundColor: theme.colors.brand_primary,
        color: theme.colors.text_white,
        fontSize: "16px",
        lineHeight: "1.5",
        letterSpacing: "0.17px",
        fontWeight: "normal",
        textTransform: "unset",
        height: "40px",
        marginBottom: "5px",
        ":hover": {
          backgroundColor: theme.colors.brand_primary
        }
      }
    }),
  addToQuote: (theme: Theme) =>
    css({
      "&.MuiButton-root": {
        backgroundColor: theme.colors.background_primary,
        color: theme.colors.brand_primary,
        fontSize: "16px",
        lineHeight: "1.5",
        letterSpacing: "0.17px",
        fontWeight: "normal",
        textTransform: "unset",
        height: "40px",
        borderColor: theme.colors.brand_primary
      }
    }),
  buttonContainer: css({
    display: "flex",
    flexDirection: "column",
    "> .MuiButton-root": {
      marginBottom: "16px",
      ":last-of-type": {
        marginBottom: "0px"
      }
    }
  }),
  currencySelectorContainer: css({
    justifyContent: "flex-end"
  }),
  currencyContainer: css({
    display: "flex",
    flexDirection: "column"
  }),
  exchangeRateContainer: css({
    justifyContent: "flex-end"
  }),
  exchangeRateText: css({
    fontSize: "10px",
    letterSpacing: "0.11px"
  })
}
