import React from "react"
import { Moment } from "moment"
import styles from "./DatesMatrix.styles"
import DatesMatrixHeader from "./DatesMatrixHeader"
import DatesMatrixCell, { DatesMatrixCellData, Highlight } from "./DatesMatrixCell"
import { useTheme } from "@basset-la/themed-components"
import Link from "@material-ui/core/Link"
import LeftArrow from "@material-ui/icons/KeyboardArrowLeftRounded"
import RightArrow from "@material-ui/icons/KeyboardArrowRightRounded"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"

export interface Props {
  variant?: "DESKTOP" | "WEB"
  startFrom: Moment
  startTo: Moment
  daysLength: number
  onCellClick: (from: Moment, to: Moment) => void
  cellData: MatrixData
  onWeekChange: (from: Moment, to: Moment) => void
  cheapestPrice?: number
  mostExpensivePrice?: number
}

export type MatrixData = Record<string, Record<string, DatesMatrixCellData | undefined>>

const DATE_FORMAT = "YYYY-MM-DD"
const WEEK_FORMAT = "D MMM"

const DatesMatrix: React.FC<Props> = ({
  variant = "DESKTOP",
  startFrom,
  startTo,
  daysLength,
  onCellClick,
  cellData,
  onWeekChange,
  mostExpensivePrice,
  cheapestPrice
}) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const nextWeekFrom = startFrom.clone().add(1, "week")
  const nextWeekTo = startTo.clone().add(1, "week")
  const prevWeekFrom = startFrom.clone().add(-1, "week")
  const prevWeekTo = startTo.clone().add(-1, "week")

  const drawHeader = () => {
    let cells = []
    for (let i = 0; i < daysLength; i++) {
      const currentDate = startFrom.clone().add(i, "days")
      cells.push(
        <th key={`title_${i}`}>
          <DatesMatrixHeader date={currentDate} />
        </th>
      )
    }
    return cells
  }

  const drawBody = () => {
    let cells = []
    for (let i = 0; i < daysLength; i++) {
      const currentToDate = startTo.clone().add(i, "days")

      let row = []

      for (let x = 0; x < daysLength; x++) {
        const currentFromDate = startFrom.clone().add(x, "days")
        const days = Math.abs(currentFromDate.diff(currentToDate, "days"))

        if (currentFromDate.isAfter(currentToDate)) {
          row.push(<td key={`cell_empty${x}`} />)
        } else {
          let data: DatesMatrixCellData | undefined
          const fromData = cellData[currentFromDate.format(DATE_FORMAT)]
          data = fromData ? fromData[currentToDate.format(DATE_FORMAT)] : undefined
          let highlight: Highlight | undefined = undefined

          if (data && data.price && mostExpensivePrice !== cheapestPrice) {
            if (data.price.value === mostExpensivePrice) {
              highlight = "MOST_EXPENSIVE"
            } else {
              if (data.price.value === cheapestPrice) {
                highlight = "CHEAPEST"
              }
            }
          }

          row.push(
            <DatesMatrixCell
              variant={variant}
              key={`cell_${x}`}
              days={days}
              data={data}
              highlight={highlight}
              onClick={() => onCellClick(currentFromDate, currentToDate)}
            />
          )
        }
      }

      cells.push(
        <tr key={`title_v_${i}`}>
          <th>
            <DatesMatrixHeader date={currentToDate} />
          </th>
          {row}
        </tr>
      )
    }

    return cells
  }

  return (
    <div className={styles.root(theme)}>
      <div className={styles.weeksButtonsContainer}>
        <div className={styles.weekInfo}>
          <Link className={styles.link(theme)} onClick={() => onWeekChange(prevWeekFrom, prevWeekTo)}>
            <LeftArrow className={styles.leftArrow} /> <span>{t("DatesMatrix.prevWeek")}</span>
          </Link>
          <span className={`${styles.week} ${styles.leftWeek}`}>{`${prevWeekFrom.format(
            WEEK_FORMAT
          )} - ${prevWeekTo.format(WEEK_FORMAT)}`}</span>
        </div>
        <div className={styles.weekInfo}>
          <Link className={styles.link(theme)} onClick={() => onWeekChange(nextWeekFrom, nextWeekTo)}>
            <span>{t("DatesMatrix.nextWeek")}</span> <RightArrow className={styles.rightArrow} />
          </Link>
          <span className={`${styles.week} ${styles.rightWeek}`}>{`${nextWeekFrom.format(
            WEEK_FORMAT
          )} - ${nextWeekTo.format(WEEK_FORMAT)}`}</span>
        </div>
      </div>
      <table className={styles.table(theme)}>
        <tbody>
          <tr key={"first_tr"}>
            <th key={"first_th"}>
              <div className={styles.mainTitle(theme)}>
                <div className={styles.mainTitleBackground(theme)} />
                <span className={styles.from}>{t("DatesMatrix.departure")}</span>
                <span className={styles.to}>{t("DatesMatrix.return")}</span>
              </div>
            </th>
            {drawHeader()}
          </tr>
          {drawBody()}
        </tbody>
      </table>
    </div>
  )
}

export default DatesMatrix
