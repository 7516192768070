import React from "react"
import Card from "../Card"
import { useTheme } from "@basset-la/themed-components"
import Link from "../Link"
import { CarsParams, FlightsParams, TravelInsuranceParams, AccommodationsParams } from "../../models/types"
import { I18N_NS } from "../../utils/constants"
import { useTranslation } from "react-i18next"
import FlightCard from "./FlightsCard"
import styles from "./SearchCard.styles"
import CarsCard from "./CarsCard"
import AccommodationsCard from "./AccommodationsCard"
import TravelInsurancesCard from "./TravelInsurancesCard"

export interface SearchCardProps {
  product: TravelInsuranceParams | CarsParams | FlightsParams | AccommodationsParams | null
  productType: "ACCOMMODATIONS" | "CARS" | "FLIGHTS" | "TRAVEL_INSURANCES"
  onClick: (product: TravelInsuranceParams | CarsParams | FlightsParams | AccommodationsParams) => void
  getLocationName?: (code: string, type: string) => Promise<string>
}

const SearchCard: React.FC<SearchCardProps> = ({ product, productType, onClick, getLocationName }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const drawContent = () => {
    switch (productType) {
      case "FLIGHTS":
        const flights = product as FlightsParams
        return <FlightCard flights={flights} />
      case "CARS":
        const cars = product as CarsParams
        return <CarsCard cars={cars} getLocationName={getLocationName!} />
      case "ACCOMMODATIONS":
        const acc = product as AccommodationsParams
        return <AccommodationsCard accommodations={acc} getLocationName={getLocationName!} />
      case "TRAVEL_INSURANCES":
        const insurances = product as TravelInsuranceParams
        return <TravelInsurancesCard insurances={insurances} getLocationName={getLocationName!} />
      default:
        return <></>
    }
  }

  return (
    <Card variant="outlined">
      <div className={styles.root(theme)}>
        <div className={styles.content}>
          {drawContent()}
          <div className={`${styles.row} ${styles.flexEnd}`}>
            <Link TextProps={{ size: 14 }} onClick={() => onClick(product!)} value={t("SearchCard.repeat")} />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default SearchCard
