import React, { useState, useEffect } from "react"
import { SearchboxParams, SearchboxConfig, SearchboxRoom, SearchboxAmenities } from "./types"
import styles from "./Searchbox.styles"
import { useTheme } from "@basset-la/themed-components"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import RegionAutocompleteField, { Props as RegionAutocompleteFieldProps } from "./RegionAutocompleteField"
import { AutocompleteItem } from "@basset-la/components-products/dist/components/ProductAutocomplete"
import ProductDatePicker from "@basset-la/components-products/dist/components/ProductDatePicker"
import { Moment } from "moment"
import Divider from "@material-ui/core/Divider"
import PriceTextField from "./PriceTextField"
import AmenitiesSelect from "./AmenitiesSelect"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import BassetButton from "@basset-la/components-commons/dist/components/Button"
import cloneDeep from "lodash/cloneDeep"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import FullScreenDialog from "@basset-la/components-products/dist/components/FullScreenDialog"
import SearchboxMobileBar from "./SearchboxMobileBar"
import AccommodationRoomSelect from "../AccommodationRoomSelect"

export interface Props extends Pick<RegionAutocompleteFieldProps, "getRegions"> {
  params: SearchboxParams
  onSearch: (p: SearchboxParams) => void
  config: SearchboxConfig
  wide?: boolean
  variant: "B2B" | "B2C"
}

const Searchbox: React.FC<Props> = ({ params, onSearch, getRegions, config, wide, variant }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)
  const isMobile = useMediaQuery("(max-width: 1024px)")

  const [openDialog, setOpenDialog] = useState(false)
  const [currentParams, setCurrentParams] = useState<SearchboxParams>(params)
  const [destinationError, setDestinationError] = useState<string | undefined>()
  const [checkinError, setCheckinError] = useState<string | undefined>()
  const [checkoutError, setCheckoutError] = useState<string | undefined>()

  useEffect(() => {
    setCurrentParams(params)
  }, [params])

  const handleOpen = () => {
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  const handleOnSelectRegion = (region: AutocompleteItem) => {
    const copy = cloneDeep(currentParams)
    copy.destination = region
    setCurrentParams(copy)
    setDestinationError(undefined)
  }

  const handleOnChangeDate = (startDate: Moment | null, endDate: Moment | null) => {
    const copy = cloneDeep(currentParams)
    copy.checkin = startDate
    copy.checkout = endDate
    setCurrentParams(copy)

    if (startDate) {
      setCheckinError(undefined)
    }
    if (endDate) {
      setCheckoutError(undefined)
    }
  }

  const handleAddRoom = () => {
    const copy: SearchboxParams = cloneDeep(currentParams)
    copy.rooms.push({ adults: 2, children: [] })
    setCurrentParams(copy)
  }

  const handleOnRoomChange = (idx: number, r: SearchboxRoom) => {
    const copy: SearchboxParams = cloneDeep(currentParams)
    copy.rooms[idx] = r
    setCurrentParams(copy)
  }

  const handleOnDeleteRoom = (idx: number) => {
    const copy: SearchboxParams = cloneDeep(currentParams)
    copy.rooms = [...copy.rooms.slice(0, idx), ...copy.rooms.slice(idx + 1, copy.rooms.length)]
    setCurrentParams(copy)
  }

  const handleOnMaxPriceChange = (v: number) => {
    const copy: SearchboxParams = cloneDeep(currentParams)
    copy.maxPrice = v
    setCurrentParams(copy)
  }

  const handleOnAmenitiesChange = (a: SearchboxAmenities) => {
    const copy: SearchboxParams = cloneDeep(currentParams)
    copy.amenities = a
    setCurrentParams(copy)
  }

  const handleOnCheckboxChange = (key: keyof Pick<SearchboxParams, "refundable" | "packageRates">) => (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    let copy = cloneDeep(currentParams)
    copy[key] = checked
    setCurrentParams(copy)
  }

  const handleOnSearch = () => {
    if (validate()) {
      onSearch(currentParams)
    }
  }

  const validate = (): boolean => {
    let valid = true
    const { destination, checkin, checkout } = currentParams

    if (!destination) {
      setDestinationError(t("Searchbox.missingDestination"))
      valid = false
    }

    if (!checkin) {
      setCheckinError(t("Searchbox.missingCheckin"))
      valid = false
    }

    if (!checkout) {
      setCheckoutError(t("Searchbox.missingCheckout"))
      valid = false
    }

    return valid
  }

  const searchBox = (
    <div className={styles.root(theme, wide || false)}>
      <span className={styles.title}>{t("Searchbox.title")}</span>

      <div className={styles.destinationDatesContainer}>
        <div className={styles.destinationContainer}>
          <RegionAutocompleteField
            id="searchbox-destination"
            getRegions={getRegions}
            onSelectRegion={handleOnSelectRegion}
            errorMessage={destinationError}
            region={currentParams.destination}
          />
        </div>
        <div className={styles.datesContainer}>
          <ProductDatePicker
            type="round-trip"
            startDate={currentParams.checkin}
            endDate={currentParams.checkout}
            onChangeDate={handleOnChangeDate}
            startDateLabel={t("Searchbox.checkin")}
            endDateLabel={t("Searchbox.checkout")}
            minDate={config.minDate}
            maxDate={config.maxDate}
            maximumNights={config.maximumNights}
            minimumNights={config.minimumNights}
            startDateError={checkinError}
            endDateError={checkoutError}
          />
        </div>
      </div>

      <Divider className={styles.divider} />
      <AccommodationRoomSelect
        maxRoomCapacity={config.maxRoomCapacity}
        maxRooms={config.maxRooms}
        rooms={currentParams.rooms}
        onAddRoom={handleAddRoom}
        onDeleteRoom={handleOnDeleteRoom}
        onRoomChange={handleOnRoomChange}
      />

      <Divider className={styles.divider} />
      {variant === "B2B" && (
        <div className={styles.filtersContainer}>
          <div>
            <PriceTextField onChange={handleOnMaxPriceChange} value={currentParams.maxPrice} />
          </div>
          <div>
            <AmenitiesSelect
              onChange={handleOnAmenitiesChange}
              amenities={currentParams.amenities ? currentParams.amenities : {}}
            />
          </div>
          <div className={styles.packageRatesContainer(theme)}>
            <FormControlLabel
              control={
                <Checkbox checked={!!currentParams.packageRates} onChange={handleOnCheckboxChange("packageRates")} />
              }
              label={t("Searchbox.packageRates")}
            />
          </div>
        </div>
      )}

      <div className={styles.footer(variant === "B2C")}>
        {variant === "B2B" && (
          <div className={styles.freeCancelContainer(theme)}>
            <FormControlLabel
              control={
                <Checkbox checked={!!currentParams.refundable} onChange={handleOnCheckboxChange("refundable")} />
              }
              label={t("Searchbox.freeCancel")}
            />
          </div>
        )}
        <div className={styles.searchButtonContainer}>
          <BassetButton fullWidth onClick={handleOnSearch} variant="contained" id="searchbox-search-btn">
            {t("Searchbox.search")}
          </BassetButton>
        </div>
      </div>
    </div>
  )

  return (
    <>
      {isMobile && !wide && (
        <>
          <SearchboxMobileBar params={currentParams} onClick={handleOpen} />
          <FullScreenDialog open={openDialog} onClose={handleClose}>
            <div className={styles.mobileContent}>{searchBox}</div>
          </FullScreenDialog>
        </>
      )}
      {(!isMobile || wide) && <>{searchBox}</>}
    </>
  )
}

export default Searchbox
