import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexFlow: "row nowrap",
      width: "100%",
      justifyContent: "space-between",
      backgroundColor: t.colors.background_primary,
      height: "100%"
    }),
  content: css({
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(2, auto)",
    gridTemplateRows: "repeat(2, auto)",
    gap: "16px"
  }),
  row: css({
    flexShrink: 0,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "8px"
  }),
  flexEnd: css({
    justifyContent: "flex-end !important",
    alignItems: "flex-end !important",
    textAlign: "right"
  }),
  children: css({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexFlow: "row wrap"
  }),
  icon: css({
    position: "relative",
    top: "-1px",
    color: "#b3b1b1",
    width: "16px !important",
    height: "16px !important"
  })
}
