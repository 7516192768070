import React from "react"

import { useTranslation } from "react-i18next"

import { formatPrice, formatPriceWeb, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import { useTheme } from "@basset-la/themed-components"
import CircularProgress from "@material-ui/core/CircularProgress"

import SearchIcon from "../../icons/SearchIcon"
import { Airline } from "../../model/types"
import { I18N_NS } from "../../utils/constants"
import styles from "./DatesMatrixCell.styles"
import DatesMatrixCellAirlineIcon from "./DatesMatrixCellAirlineIcon"

interface Props {
  variant?: "DESKTOP" | "WEB"
  data?: DatesMatrixCellData
  days: number
  onClick: () => void
  highlight?: Highlight
}

export type Highlight = "MOST_EXPENSIVE" | "CHEAPEST"

export type DatesMatrixCellType = "SELECTED" | "LOADING" | "NO_RESULT"

export type DatesMatrixCellData = {
  price?: {
    value: number
    currency: string
  }
  airlines?: Airline[]
  type?: DatesMatrixCellType
}

const DatesMatrixCell: React.FC<Props> = ({ variant = "DESKTOP", data, onClick, days, highlight }) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const theme = useTheme()

  return (
    <td
      onClick={() => {
        if (data && data.type === "NO_RESULT") {
          return
        }
        onClick()
      }}
      className={styles.root(theme, data ? data.type : undefined, highlight)}
    >
      <div>
        {data && data.price && (
          <div className={styles.dataCellContainer}>
            <DatesMatrixCellAirlineIcon airlines={data.airlines} />
            <div className={styles.priceContainer}>
              <span className={styles.price}>
                {variant === "DESKTOP" && formatPrice(data.price.value, locale, data.price.currency)}
                {variant === "WEB" && formatPriceWeb(data.price.value, locale, data.price.currency)}
              </span>
              <span className={styles.days}>{t("DatesMatrixCell.dayCount", { count: days })}</span>
            </div>
          </div>
        )}
        {!data && <SearchIcon />}
        {data && data.type === "LOADING" && <CircularProgress className={styles.loading(theme)} size={25} />}
        {data && data.type === "NO_RESULT" && <span>-</span>}
      </div>
    </td>
  )
}

export default DatesMatrixCell
