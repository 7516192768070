export const getDistribution = (distributions: string[]) => {
  let adults = 0
  let children = 0
  for (const room of distributions) {
    const distribution = room.split("-")
    adults += distribution[0] ? +distribution[0] : 0
    children += distribution.length - 1 > 0 ? distribution.length - 1 : 0
  }

  return { adults, children }
}
