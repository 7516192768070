import { useTheme } from "@basset-la/themed-components"
import React from "react"
import styles from "./FlightBrandSelect.styles"
import Button from "@basset-la/components-commons/dist/components/Button"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Text from "@basset-la/components-commons/dist/components/Text"
import { formatPrice, formatPriceWeb, getLocale } from "@basset-la/components-products"
import { UpSellFare } from "../../model"

export interface Props {
  variant?: "WEB" | "DESKTOP"
  idx: number
  fare: UpSellFare
  selected: boolean
  onSelect: () => void
  isSingleCategory: boolean
}

const FlightBrandSelect: React.FC<Props> = ({
  variant = "DESKTOP",
  idx,
  fare,
  selected,
  onSelect,
  isSingleCategory
}) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)

  const getTitle = () => {
    const title = `${t("FlightBrand.title", { count: idx })}`

    if (!isSingleCategory) {
      return title
    }

    return `${title} ${t("ClusterBrands.singleCategory")}`
  }

  return (
    <div className={styles.root(isSingleCategory)}>
      <div className={styles.title(theme)}>{getTitle()}</div>

      <div className={styles.content}>
        <div className={styles.cost}>
          <Text size={14} variant="light">
            {t("FlightBrand.additionalCost")}
          </Text>
          <Text size={18} variant="regular">
            {fare.upsell_price.amount >= 0 && "+"}
            {variant === "DESKTOP" && formatPrice(fare.upsell_price.amount, locale, fare.price.currency)}
            {variant === "WEB" && formatPriceWeb(fare.upsell_price.amount, locale, fare.price.currency)}
          </Text>
        </div>

        <Button className={styles.button} variant={selected ? "contained" : "outlined"} onClick={onSelect}>
          {t(`FlightBrand.${selected ? "selected" : "select"}`)}
        </Button>
      </div>
    </div>
  )
}

export default FlightBrandSelect
