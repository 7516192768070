import React, { FC, useEffect, useMemo, useState } from "react"
import Text from "../Text"
import { CarsParams } from "../../models/types"
import { DISPLAY_DATETIME_FORMAT, I18N_NS, INPUT_DATETIME_FORMAT } from "../../utils/constants"
import moment from "moment"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import TodayIcon from "@material-ui/icons/Today"
import PersonIcon from "@material-ui/icons/Person"
import styles from "./SearchCard.styles"
import { useTranslation } from "react-i18next"
import { Skeleton } from "@material-ui/lab"

export interface Props {
  cars: CarsParams
  getLocationName: (code: string, type: string) => Promise<string>
}

const CarsCard: FC<Props> = ({ cars, getLocationName }) => {
  const { t } = useTranslation(I18N_NS)
  const [pickUpLocation, setPickUpLocation] = useState<string>("")
  const [pickOffLocation, setPickOffLocation] = useState<string>("")

  const showDropOff = useMemo(() => {
    return (
      cars.drop_off_location.code !== cars.pick_up_location.code &&
      cars.drop_off_location.type !== cars.pick_up_location.type
    )
  }, [cars.drop_off_location, cars.pick_up_location])

  useEffect(() => {
    if (cars) {
      getLocationName(cars.pick_up_location?.code, cars.pick_up_location?.type).then(location =>
        setPickUpLocation(location)
      )
      if (cars.drop_off_location) {
        getLocationName(cars?.drop_off_location?.code, cars?.drop_off_location?.type).then(location =>
          setPickOffLocation(location)
        )
      }
    }
  }, [cars, getLocationName])

  if (!pickUpLocation && !pickOffLocation) {
    return (
      <>
        <div className={styles.row}>
          <Skeleton variant="rect" animation="pulse" width="100%" height="20px" />
        </div>
        <div className={styles.row}>
          <Skeleton variant="rect" animation="pulse" width="100%" height="20px" />
        </div>
        <div className={styles.row}>
          <Skeleton variant="rect" animation="pulse" width="100%" height="20px" />
        </div>
        <div className={styles.row}>
          <Skeleton variant="rect" animation="pulse" width="100%" height="20px" />
        </div>
        <div className={styles.row}>
          <Skeleton variant="rect" animation="pulse" width="100%" height="20px" />
        </div>
      </>
    )
  }

  return (
    <>
      <div className={styles.row}>
        <LocationOnIcon className={styles.icon} />
        <Text size={14}>{pickUpLocation}</Text>
      </div>
      <div className={styles.row}>
        {showDropOff && (
          <>
            <LocationOnIcon className={styles.icon} />
            <Text size={14}>{pickOffLocation}</Text>
          </>
        )}
      </div>
      <div className={styles.row}>
        <TodayIcon className={styles.icon} />
        <Text size={14}>{`${moment(cars.pick_up_date, INPUT_DATETIME_FORMAT, true)
          .utc()
          .format(DISPLAY_DATETIME_FORMAT)}${
          showDropOff
            ? ` - ${moment(cars.drop_off_date, INPUT_DATETIME_FORMAT, true)
                .utc()
                .format(DISPLAY_DATETIME_FORMAT)}`
            : ""
        }`}</Text>
      </div>
      <div className={styles.row} />
      <div className={styles.row}>
        <PersonIcon className={styles.icon} />
        <Text size={14}>{t("SearchCard.years", { count: cars.driver_age })}</Text>
      </div>
    </>
  )
}

export default CarsCard
