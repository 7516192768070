export const I18N_NS = "components-products"

export const colors = {
  GREY: "#4a4a4a",
  GREY2: "#6b797c",
  GREY3: "#ccc",
  LIGHT_GREY: "#9b9b9b",
  LIGHTER_GREY: "#eaeaea",
  GREEN: "#579b08",
  GREEN2: "#02ae02",
  GREEN3: "#156555",
  LIGHT_GREEN: "#e4facb",
  LIGHTEST_GREEN: "#d2eae5",
  WHITE: "#fff",
  RED: "#d0021b",
  LIGHT_RED: "#f6dfe2",
  SUPER_LIGHT_GREY: "#f4f4f4",
  LIGHT_BLUE: "#36a4d5",
  LIGHTEST_BLUE: "#f6f8ff",
  LIGHT_YELLOW: "#FEF8E5",
  YELLOW: "#e09b01",
  BLUE: "#126aff"
}
