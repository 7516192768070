import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexFlow: "row nowrap",
      width: "100%",
      backgroundColor: t.colors.background_primary
    }),
  content: css({
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  }),
  label: css({
    flexShrink: 0,
    display: "flex",
    justifyContent: "flex-start",
    alignSelf: "flex-start",
    margin: "0 16px 16px 0",
    "& > span": {
      fontWeight: 500
    }
  }),
  children: css({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    "@media (max-width: 768px)": {
      justifyContent: "center",
      flexDirection: "column"
    },
    "& > div": {
      flex: "0 1 50%",
      "@media (max-width: 768px)": {
        flex: "1 1 100%"
      }
    }
  }),
  noResults: css({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "75px",
    flex: "0 1 100% !important"
  })
}
