import React, { useMemo } from "react"

import moment from "moment"
import { useTranslation } from "react-i18next"

import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import { useTheme } from "@basset-la/themed-components"
import CalendarIcon from "@material-ui/icons/InsertInvitationRounded"
import PersonIcon from "@material-ui/icons/PersonRounded"
import HotelIcon from "@material-ui/icons/HotelRounded"

import { I18N_NS } from "../../utils/constants"
import styles from "./QuotedPDFHeader.styles"

export interface Props {
  total: number
  number: number
  currency: string
  checkin: string
  checkout: string
  roomsQty: number
  regionName: string
  adultsCount: number
  childrenAges?: number[]
}

const QuotedPDFHeader: React.FC<Props> = ({
  number,
  total,
  currency,
  checkin,
  checkout,
  roomsQty,
  regionName,
  adultsCount,
  childrenAges
}) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const theme = useTheme()
  const guestsBreakdown = useMemo(() => {
    let result = t("QuotedPDFHeader.adults_count", { count: adultsCount })
    if (childrenAges?.length) {
      const ages = Array.from(new Set(childrenAges).values()).sort((a, b) => a - b)
      result += `, ${t("QuotedPDFHeader.children_count", { count: childrenAges.length })} (`
      ages.forEach((age, index) => {
        result += t("QuotedPDFHeader.year", { count: age })
        result += index === ages.length - 1 ? ")" : ", "
      })
    }
    return result
  }, [adultsCount, childrenAges, t])
  const locale = getLocale(i18n)
  const formattedTotal = formatPrice(total, locale, currency, { hideCurrency: true })
  const formattedCheckin = moment(checkin, "YYYY-MM-DD")
    .locale(locale)
    .format("DD MMM YYYY")
  const formattedCheckout = moment(checkout, "YYYY-MM-DD")
    .locale(locale)
    .format("DD MMM YYYY")

  return (
    <div className={styles.root(theme)}>
      <div className={styles.detail}>
        <span className={styles.title}>{t("QuotedPDFHeader.title", { count: number, currency: currency })}</span>
        <span className={styles.originDest}>{regionName}</span>
        <span className={styles.datePassContainer}>
          <span className={styles.dateContainer}>
            <CalendarIcon className={styles.icon(theme)} />{" "}
            <span className={styles.text}>{`${formattedCheckin} - ${formattedCheckout}`}</span>
          </span>
          <span className={styles.roomsContainer}>
            <HotelIcon className={styles.icon(theme)} />
            <span className={styles.text}>{t("QuotedPDFHeader.rooms", { count: roomsQty })}</span>
          </span>
          <span className={styles.guestsContainer}>
            <PersonIcon className={styles.icon(theme)} />
            <span className={styles.text}>{guestsBreakdown}</span>
          </span>
        </span>
      </div>
      <div className={styles.price(theme)}>
        <span className={styles.totalLabel}>{t("QuotedPDFHeader.total")}</span>
        <span className={styles.total}>
          <span className={styles.currency}>{currency}</span>
          <span>{formattedTotal}</span>
        </span>
      </div>
    </div>
  )
}

export default QuotedPDFHeader
