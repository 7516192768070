import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      display: "flex",
      flexFlow: "column",
      backgroundColor: theme.colors.background_primary,
      fontFamily: "Roboto"
    }),
  container: (theme: Theme) =>
    css({
      borderRadius: "4px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.colors.background_darker,
      borderImage: "initial",
      marginBottom: "8px",
      display: "flex"
    }),
  roomBriefContainer: css({
    width: "100%",
    boxSizing: "border-box"
  }),
  mainContent: css({
    padding: "16px"
  }),
  disclamer: (theme: Theme) =>
    css({
      fontSize: "14px",
      letterSpacing: "0.15px",
      color: theme.colors.text_primary,
      margin: 0,
      padding: "16px",
      borderTop: `1px solid ${theme.colors.background_darker}`
    }),
  photos: css({
    borderRadius: "4px",
    overflow: "hidden",
    flex: 2,
    maxWidth: "150px",
    marginRight: "16px",
    display: "flex",
    "@media screen and (max-width: 425px)": {
      marginRight: 0,
      width: "100%",
      height: "128px",
      borderRadius: 0
    }
  }),
  priceBreakdown: (theme: Theme) =>
    css({
      boxSizing: "border-box",
      height: "100%",
      width: "240px",
      padding: "16px",
      borderLeft: `1px solid ${theme.colors.background_darker}`
    }),
  infoContainer: css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    minHeight: "112px",
    flexShrink: 1,
    minWidth: 0,
    flexGrow: 1,
    justifyContent: "space-between",
    padding: "16px",
    "> div": {
      flex: 1
    }
  }),
  remarksSection: css({
    padding: "16px",
    backgroundColor: "#1134D71A",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    margin: "16px"
  }),
  remarkHeader: css({
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "16px",
    textAlign: "left",
    color: "#1134D7",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "4px"
  }),
  remarkDescription: (theme: Theme) =>
    css({
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "16px",
      letterSpacing: "0.14700011909008026px",
      textAlign: "left",
      color: theme.colors.text_primary
    }),
  remarkIcon: css({
    width: "22px !important",
    fill: "#1134D7 !important"
  })
}
