import React from "react"
import { Currency, AccommodationFare } from "../../model/types"

import CurrencySwitcher from "@basset-la/components-products/dist/components/CurrencySwitcher"

import {
  convertAccommodationFareToResellerPriceBreakdown,
  convertToResellerCostEffectivenessBreakdown,
  calculateResellerPayment
} from "../../utils/helpers/helpersV2"
import AccommodationsPricebox from "../PriceboxBreakdown/PriceboxBreakdown"
import PaymentDetailBreakdown from "../PriceboxBreakdownPaymentDetail/PriceboxBreakdownPaymentDetail"
import CostEffectivenessBreakdown from "../PriceboxBreakdownCostEffectiveness/PriceboxBreakdownCostEffectiveness"
import styles from "./PriceboxBreakdownReseller.syles"
import PriceboxBreakdownVoucherDetail from "@basset-la/components-products/dist/components/PriceboxBreakdownVoucherDetail"
import { ReservationProduct } from "@basset-la/components-products/dist/components/PriceboxBreakdownVoucherDetail/types"

export interface Props {
  accommodationFare: AccommodationFare
  rooms: number
  guests: number
  currencySelector?: Currency
  taxesDisclaimer?: string
  reservationProducts?: ReservationProduct
  onGenerateVoucher?: () => void
}

const PriceboxBreakdownReseller: React.FC<Props> = ({
  accommodationFare,
  rooms,
  guests,
  currencySelector,
  taxesDisclaimer,
  reservationProducts,
  onGenerateVoucher
}) => {
  const priceBreakdown = convertAccommodationFareToResellerPriceBreakdown(accommodationFare, rooms, guests)
  const costEffectivenessBreakdown = convertToResellerCostEffectivenessBreakdown(accommodationFare)
  const costDetailBreakdown = calculateResellerPayment(accommodationFare)

  const isVoucherBlocked = (p?: ReservationProduct): boolean => {
    return !!p?.statement && p.statement === "CASHED"
  }

  return (
    <div className={`${styles.root}`}>
      {currencySelector && currencySelector.currency !== currencySelector.originalCurrency.currency_code && (
        <CurrencySwitcher
          currency={currencySelector.currency}
          originalCurrency={currencySelector.originalCurrency}
          enabledSwitch={currencySelector.enabledSwitch}
        />
      )}
      <AccommodationsPricebox priceBreakdown={priceBreakdown} taxesDisclaimer={taxesDisclaimer} />
      <CostEffectivenessBreakdown costEffectivenessBreakdown={costEffectivenessBreakdown} />
      <PaymentDetailBreakdown paymentDetailBreakdown={costDetailBreakdown} />
      <PriceboxBreakdownVoucherDetail
        reservationProducts={reservationProducts}
        isVoucherBlocked={() => isVoucherBlocked(reservationProducts)}
        onGenerateVoucher={onGenerateVoucher}
      />
    </div>
  )
}

export default PriceboxBreakdownReseller
